import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-data-table__wrapper" }
const _hoisted_2 = { class: "app-data-table-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_table_messages = _resolveComponent("data-table-messages")!
  const _component_data_table_body = _resolveComponent("data-table-body")!
  const _component_data_table_footer = _resolveComponent("data-table-footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-data-table", {"action": _ctx.isAction}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", null, [
        _renderSlot(_ctx.$slots, "headers", { headers: _ctx.headers }),
        _createElementVNode("tbody", null, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_data_table_messages, {
                key: 0,
                text: "Завантаження данних",
                length: _ctx.headers.length,
                loading: ""
              }, null, 8, ["length"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.items.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item, i) => {
                      return (_openBlock(), _createBlock(_component_data_table_body, {
                        key: i,
                        item: item,
                        headers: _ctx.headers,
                        onRowClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('row-clicked', $event)))
                      }, _createSlots({
                        expanded: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "expanded", { item: item })
                        ]),
                        _: 2
                      }, [
                        _renderList(_ctx.hasSlots(), (slot) => {
                          return {
                            name: slot,
                            fn: _withCtx(({ item }) => [
                              _renderSlot(_ctx.$slots, slot, { item: item })
                            ])
                          }
                        })
                      ]), 1032, ["item", "headers"]))
                    }), 128))
                  : (_openBlock(), _createBlock(_component_data_table_messages, {
                      key: 1,
                      text: "У таблиці немає даних",
                      length: _ctx.headers.length
                    }, null, 8, ["length"]))
              ], 64))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_data_table_footer, {
        pagination: _ctx.pagination,
        onPageChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('page-changed', $event)))
      }, null, 8, ["pagination"])
    ])
  ], 2))
}