
import {defineComponent, ref, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-removed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: null
		},
		id: {
			type: Number
		},
		service: {
			type: Object as PropType<{remove: Function}>,
			required: true
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const isLoading = ref(false);
		const remove = async () => {
			isLoading.value = true;

			try {
				await props.service.remove(props.id);

				store.commit('notification/successMessage', {title: t("form.notify.success.removed", { name: ucFirst(props.name) })});
				emit("item-removed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		};

		const closeModal = () => emit('dialog-closed');

		return { t, isLoading, remove, closeModal }
	}
})
