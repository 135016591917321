import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex align-center"
}
const _hoisted_3 = {
  key: 2,
  class: "d-flex align-center"
}
const _hoisted_4 = {
  key: 0,
  class: "app-data-table__expanded"
}
const _hoisted_5 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_tooltip = _resolveComponent("app-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('row-clicked', _ctx.item))),
      class: _normalizeClass({"active": _ctx.isExpanded})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, i) => {
        return (_openBlock(), _createElementBlock("td", {
          class: _normalizeClass(header.classCell),
          key: i
        }, [
          (_ctx.hasSlot(`item.${header.value}`))
            ? _renderSlot(_ctx.$slots, `item.${header.value}`, {
                key: 0,
                item: _ctx.item
              })
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (header.icon)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createTextVNode(_toDisplayString(_ctx.get(_ctx.item, header.value)) + " ", 1),
                      _createVNode(_component_app_tooltip, { placement: "bottom-end" }, {
                        content: _withCtx(() => [
                          _createTextVNode(_toDisplayString(header.icon.title), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_app_button, {
                            icon: "",
                            class: "ml-1",
                            onClick: _withModifiers(($event: any) => (header.icon.action(_ctx.item)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_app_icon, {
                                icon: header.icon.name,
                                size: 16
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : (header.status)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_app_button, {
                          xsmall: "",
                          outlined: "",
                          onClick: _withModifiers(($event: any) => (header.status.action(_ctx.item)), ["stop"]),
                          loading: _ctx.item.id == header.status.itemActiveId,
                          class: _normalizeClass(_ctx.get(_ctx.item, header.value) ? "text-red" : "text-green"),
                          style: {"min-width":"78px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_icon, {
                              icon: _ctx.get(_ctx.item, header.value) ? "lock" : "unlock",
                              size: 14
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.get(_ctx.item, header.value) ? _ctx.ucFirst(_ctx.t("dictionary.lock")) : _ctx.ucFirst(_ctx.t("dictionary.unlock"))), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "loading", "class"])
                      ]))
                    : (header.isExpanded)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_app_button, {
                            icon: "",
                            class: _normalizeClass(["app-data-table__expanded-button ml-1", {"app-data-table__expanded-button-active": _ctx.isExpanded}]),
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isExpanded = !_ctx.isExpanded), ["stop"]))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_app_icon, {
                                icon: "arrow-down",
                                size: 16
                              })
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _createTextVNode(_toDisplayString(_ctx.get(_ctx.item, header.value)), 1)
                        ], 64))
              ], 64))
        ], 2))
      }), 128))
    ], 2),
    (_ctx.isExpanded)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
          _createElementVNode("td", {
            colspan: _ctx.headers.length
          }, [
            _renderSlot(_ctx.$slots, "expanded")
          ], 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}