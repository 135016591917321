
import { defineComponent, ref } from 'vue'

export default defineComponent({
	emits: ["update-date-picker"],
	props: {
		dates: Array
	},
	setup(props) {
		const date = ref();
		date.value = props.dates ?? []

		return { date }
	}
})
