
import { defineComponent, PropType } from 'vue'
import DataTableBody from '@/components/tables/DataTableBody.vue';
import DataTableFooter from '@/components/tables/DataTableFooter.vue';
import DataTableMessages from '@/components/tables/DataTableMessages.vue';
import {List} from '@/components/tables/DataTable';

export default defineComponent({
	emits: ['row-clicked', 'page-changed'],
	props: {
		headers: {
			type: Array
		},
		items: {
			type: Array
		},
		pagination: {
			type: Object as PropType<List>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		isAction: {
			type: Boolean,
			default: false
		}
	},
	components: {
		DataTableBody,
		DataTableFooter,
		DataTableMessages
	},
	setup(_, {slots}) {
		const hasSlots = () => Object.keys(slots).filter((slot: any) => slot.toLowerCase().includes("item."));
		return { hasSlots }
	}
})
