import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-data-table-header-icon ml-auto" }
const _hoisted_2 = { class: "app-filter-autocomplete pa-5" }
const _hoisted_3 = { class: "app-filter-autocomplete-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_autocomplete = _resolveComponent("app-autocomplete")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    arrow: "",
    isOpen: _ctx.isOpened
  }, {
    content: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_app_autocomplete, {
            items: _ctx.options,
            selected: _ctx.selected,
            onItemSelected: ($event: any) => (_ctx.select($event, close))
          }, null, 8, ["items", "selected", "onItemSelected"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_app_badge, {
        dot: "",
        color: _ctx.selected.id ? "bg-blue" : ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_app_icon, {
              icon: "filter",
              size: 16
            })
          ])
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _: 1
  }, 8, ["isOpen"]))
}