import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-data-table-header-filter" }
const _hoisted_2 = { class: "app-data-table-header-icon ml-auto" }
const _hoisted_3 = {
  key: 0,
  class: "app-badge app-badge--dot primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_floating_wrapper = _resolveComponent("app-floating-wrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_floating_wrapper, {
      left: "",
      ref: "floatingWrapperAutocomplete"
    }, {
      activator: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_app_icon, {
            icon: "filter",
            size: "16"
          })
        ])
      ]),
      _: 1
    }, 512),
    (_ctx.date && _ctx.date.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}