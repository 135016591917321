import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col py-0" }
const _hoisted_3 = { class: "subtitle-1" }
const _hoisted_4 = { class: "col-auto py-0" }
const _hoisted_5 = { class: "text-body-2 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_dialog = _resolveComponent("app-dialog")!

  return (_openBlock(), _createBlock(_component_app_dialog, {
    open: _ctx.open,
    width: 450,
    onDialogClosed: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("button.remove")) + " " + _toDisplayString(_ctx.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            icon: "",
            onClick: _ctx.closeModal,
            class: "ma-n3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: "cross",
                size: 16
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("form.notify.remove", {name: _ctx.name})), 1),
      _createVNode(_component_app_button, {
        block: "",
        large: "",
        color: "bg-red text-white",
        onClick: _ctx.remove,
        loading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("button.remove")), 1)
        ]),
        _: 1
      }, 8, ["onClick", "loading"])
    ]),
    _: 1
  }, 8, ["open", "onDialogClosed"]))
}