
import { defineComponent, ref } from 'vue'

export default defineComponent({
	emits: ["update"],
	props: {
		options: {
			type: Object
		}
	},
	setup() {
		const selected = ref(null);

		return { selected }
	}
})
