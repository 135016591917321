
import {defineComponent, ref, computed, PropType} from 'vue';
import {List} from '@/components/tables/DataTable';

export default defineComponent({
	emits: ['page-changed'],
	props: {
		pagination: {
			type: Object as PropType<List>,
			default: null
		}
	},
	setup(props, {emit}) {
		const sizes = ref([
			{id: 5, name: 5},
			{id: 10, name: 10},
			{id: 20, name: 20},
			{id: 50, name: 50},
			{id: 100, name: 100}
		])
		const selectedSize = ref();
		selectedSize.value = sizes.value.find(el => el.id === props.pagination.size);

		const numberOfItems = computed(() =>
			props.pagination.current * props.pagination.size >= props.pagination.total
				? props.pagination.total
				: props.pagination.current * props.pagination.size)

		const updatePagination = ($event: List) => emit('page-changed', {...props.pagination, ...$event});

		return { sizes, selectedSize, numberOfItems, updatePagination }
	}
})
