
import { defineComponent, ref, PropType } from 'vue'
// @ts-ignore
import Popper from 'vue3-popper'

export default defineComponent({
	emits: ["update-autocomplete"],
	props: {
		options: {
			type: Array as PropType<{[key: string]: any}[]>,
			required: true
		}
	},
	components: { Popper },
	setup(props, {emit}) {
		const isOpened = ref(false);
		const selected = ref({});

		function select(value: {[key: string]: any}| null, close?: Function) {
			selected.value = value ?? props.options[0];
			emit("update-autocomplete", selected.value);

			if(!close) return;
			close();
		}
		// select(null);

		return { selected, select, isOpened }
	}
})
