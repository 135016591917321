import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row align-center" }
const _hoisted_2 = { class: "col d-flex align-center" }
const _hoisted_3 = { class: "app-data-table-footer-total" }
const _hoisted_4 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_pagination = _resolveComponent("app-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.pagination.total > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.numberOfItems) + " з " + _toDisplayString(_ctx.pagination.total), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_app_select, {
                items: _ctx.sizes,
                selected: _ctx.selectedSize,
                onSelectItem: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedSize = $event, _ctx.updatePagination({size: $event.id, current: 1}))),
                class: "app-data-table-footer-select"
              }, {
                iconAppend: _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    icon: "sorting",
                    size: 12
                  })
                ]),
                _: 1
              }, 8, ["items", "selected"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_app_pagination, {
        total: _ctx.pagination.total,
        pageNumber: _ctx.pagination.current,
        pageSize: _ctx.pagination.size,
        onPageChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePagination({current: $event})))
      }, null, 8, ["total", "pageNumber", "pageSize"])
    ])
  ]))
}