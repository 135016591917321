
import { defineComponent } from 'vue'
import TableSelect from '@/components/tables/filters/Select.vue';
import TableAutocomplete from '@/components/tables/filters/Autocomplete.vue';
import TableDatePicker from '@/components/tables/filters/DatePicker.vue';

export default defineComponent({
	emits: ["sorting-changed", "filtering-changed", "update-date-picker", "update-autocomplete"],
	props: {
		headers: {
			type: Array
		},
		sorting: {
			type: Object
		},
		dates: {
			type: Array
		}
	},
	components: {
		TableSelect,
		TableAutocomplete,
		TableDatePicker
	}
})
