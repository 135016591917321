import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "app-data-table-header-th" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_table_select = _resolveComponent("table-select")!
  const _component_table_autocomplete = _resolveComponent("table-autocomplete")!
  const _component_table_date_picker = _resolveComponent("table-date-picker")!

  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (item, i) => {
        return (_openBlock(), _createElementBlock("th", {
          key: i,
          style: _normalizeStyle(`${item.width ? `width: ${item.width}` : ""}`)
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(item.text), 1),
            (item.sortCriterion)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["app-data-table-header-icon", {"active": item.sortCriterion === _ctx.sorting.sortCriterion && _ctx.sorting.sortDirection === 1}]),
                  onClick: ($event: any) => (_ctx.$emit("sorting-changed", item))
                }, [
                  _createVNode(_component_app_icon, {
                    icon: "arrow-down",
                    size: 16
                  })
                ], 10, _hoisted_2))
              : _createCommentVNode("", true),
            (item.filters && item.filters.select)
              ? (_openBlock(), _createBlock(_component_table_select, {
                  key: 1,
                  options: _ctx.options[item.filters.optionsName],
                  onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("filtering-changed", $event)))
                }, null, 8, ["options"]))
              : _createCommentVNode("", true),
            (item.filters && item.filters.autocomplete)
              ? (_openBlock(), _createBlock(_component_table_autocomplete, {
                  key: 2,
                  options: item.filters.options,
                  onUpdateAutocomplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("update-autocomplete", $event)))
                }, null, 8, ["options"]))
              : _createCommentVNode("", true),
            (item.filterDatePicker)
              ? (_openBlock(), _createBlock(_component_table_date_picker, {
                  key: 3,
                  dates: _ctx.dates[item.value],
                  onUpdateDatePicker: ($event: any) => (_ctx.$emit("update-date-picker", {value: $event, key: item.value}))
                }, null, 8, ["dates", "onUpdateDatePicker"]))
              : _createCommentVNode("", true)
          ])
        ], 4))
      }), 128))
    ])
  ]))
}