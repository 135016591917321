import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-data-table__empty-wrapper" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "d-flex align-center justify-center" }
const _hoisted_4 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("td", {
          key: 0,
          colspan: _ctx.length
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_spinner, {
              loading: _ctx.loading,
              class: "mr-4"
            }, null, 8, ["loading"]),
            _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
          ])
        ], 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("td", {
          key: 1,
          colspan: _ctx.length
        }, _toDisplayString(_ctx.text), 9, _hoisted_4))
  ]))
}