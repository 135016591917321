
import {defineComponent, ref} from 'vue'
import {useI18n} from 'vue-i18n';
import {ucFirst} from '@/helpers/firstRegister';
import get from 'lodash/get';

export default defineComponent({
	emits: ["row-clicked"],
	props: {
		item: {
			type: Object
		},
		headers: {
			type: Array
		}
	},
	setup(_, {slots}) {
		const { t } = useI18n();

		const isExpanded = ref(false);

		const hasSlot = (name = 'default') => !!slots[ name ];

		return { t, ucFirst, get, isExpanded, hasSlot }
	}
})
